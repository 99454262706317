import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./authentication/PrivateRoute";
import ScrollToTop from "./common/ScrollToTop";

import HomePage from "./main/home/HomePage";
import ProfilePage from "./authentication/profile/ProfilePage";
import UnderConstruction from "./common/UnderConstruction";
import NotFoundPage from "./common/NotFoundPage";
import NotPermittedPage from "./common/NotPermittedPage";

import { PermissionTypes } from "../components/authentication/permissionsGroups";

import SignIn from "./authentication/SignIn";
import ChangePasswordPage from "./authentication/ChangePasswordPage";
import ForgotPassword from "./authentication/ForgotPassword";
import ResetPassword from "./authentication/ResetPassword";
import WellComePage from "./authentication/WellComePage";

import ComunitaEnergeticaPage from "./main/comunitaEnergetiche/comunitaEnergetica/ComunitaEnergeticaPage";

export enum Routes_path {
	HOME = "/",
	SIGNIN = "/signin/",
	WELL_COME = "/well_come/:uidb64/:token/",
	FORGOT_PASSWORD = "/forgotPassword/",
	CHANGE_PASSWORD = "/changepassword/",
	RESET_PASSWORD = "/resetPassword/:uidb64/:token/",
	UNDER_COSTRUCTION = "/UnderConstruction/",

	DASHBOARD = "/dashboard/",
	COMUNITA_ENERGETICHE = "/comunita_energetiche/",
	COMUNITA_ENERGETICA = "/comunita_energetica/",
	TIPI_DOCUMENTO = "/tipi_documento/",
	TIPO_DOCUMENTO = "/tipo_documento/",
	UTENTI = "/utenti/",
	UTENTE = "/utente/",
}

const RouterApp = () => {
	return (
		<ScrollToTop>
			<Routes>
				<Route path={Routes_path.HOME} element={<HomePage />} />
				<Route path={Routes_path.SIGNIN} element={<SignIn />} />
				{/* <Route  path={"/register/"} element={Register} /> */}
				<Route path={Routes_path.WELL_COME} element={<WellComePage />} />
				<Route
					path={Routes_path.FORGOT_PASSWORD}
					element={<ForgotPassword />}
				/>
				<Route path={Routes_path.RESET_PASSWORD} element={<ResetPassword />} />
				{/* <Route
					path={Routes_path.UNDER_COSTRUCTION}
					element={<UnderConstruction />}
				/> */}
				<Route
					path={Routes_path.UNDER_COSTRUCTION}
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.ALL]}
							element={<UnderConstruction />}
						/>
					}
				/>

				<Route
					path={Routes_path.COMUNITA_ENERGETICA + ":comunitaEnergetica_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<ComunitaEnergeticaPage />}
						/>
					}
				/>

				<Route
					path="/me"
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.ALL]}
							element={<ProfilePage />}
						/>
					}
				/>
				<Route
					path={Routes_path.CHANGE_PASSWORD}
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.ALL]}
							element={<ChangePasswordPage />}
						/>
					}
				/>
				<Route path="/notPermitted" element={<NotPermittedPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</ScrollToTop>
	);
};

export default RouterApp;
