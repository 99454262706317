import {
	handleResponse,
	handleError,
	headerset,
	ACCESS_TOKEN,
	REFRESH_TOKEN,
} from "./apiUtils";
import {
	CredenzialiUser,
	Utente,
	ChangePassword,
	ResetPasswordProps,
} from "../components/authentication/authenticationSlice";

const baseUrl = process.env.API_URL + "/api/";

export function userCreate() {
	// return fetch(baseUrl).then(handleResponse).catch(handleError);
}

export function tokenObtain(user: CredenzialiUser) {
	return fetch(baseUrl + "token/obtain/", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({
			username: user.username,
			password: user.password,
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function tokenRefresh() {
	const refresh_token = localStorage.getItem(REFRESH_TOKEN);
	return fetch(baseUrl + "token/refresh/", {
		method: "POST",
		headers: {
			Authorization: "JWT " + localStorage.getItem(ACCESS_TOKEN),
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({
			refresh: refresh_token,
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getUtente() {
	return fetch(baseUrl + "me/", {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveUser(utente: Utente) {
	return fetch(baseUrl + "me/", {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...utente,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function changePassword(passwordStack: ChangePassword) {
	return fetch(baseUrl + "me/change_password", {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...passwordStack,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function requestPasswordResetEmail(email: string) {
	return fetch(baseUrl + "request-password-reset/", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			// Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			email: email,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function passwordReset({
	uidb64,
	token,
	new_password,
	new_password2,
}: ResetPasswordProps) {
	return fetch(baseUrl + "password-reset/" + uidb64 + "/" + token + "/", {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			// Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			new_password: new_password,
			new_password2: new_password2,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function passwordResetCheck({
	uidb64,
	token,
	new_password,
	new_password2,
}: ResetPasswordProps) {
	return fetch(baseUrl + "password-reset/" + uidb64 + "/" + token + "/", {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			// Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ###################################

export function fetchUtentiStaff(
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = process.env.API_URL + "/api/utenti_staff/";

	if (numeroRecord != undefined || search != undefined || page != undefined) {
		url += "?";
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ###################################

export function fetchUtentiApplicativo(
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = process.env.API_URL + "/api/utenti_applicativo/";

	if (numeroRecord != undefined || search != undefined || page != undefined) {
		url += "?";
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
