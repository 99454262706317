import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Link as ReactLink} from "react-router-dom";

import {RootState} from "../../../redux/store";

import {UtenteLogged} from "../../authentication/authenticationSlice";
import {
    ErrorStatusTypes,
} from "../../common/errorsDeclarations";
import SignIn from "../../authentication/SignIn";

import CircularProgress from "@mui/material/CircularProgress";
import {Routes_path} from "../../routerApp";

import hero_image from "../../../../static/images/common/CER.png";
// import hero_image from "../../../../static/images/common/heroImage.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Parallax from "../../common/Parallax/Parallax";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import {theme} from "components/theme";
import {Button} from "@mui/material";
import logo from "../../../../static/images/logo/Logo_GruppoE43.png";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";


const containerFluid = {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    // marginLeft: "auto",
    width: "100%",
};
const container = {
    ...containerFluid,
    backgroundColor: '#03b0e266'
};

const parallaxContainer = {
    zIndex: "12",
    color: "#FFFFFF",
    pb: 6,
    ...container,

    //
    minHeight: 'calc(100vh - 70px)',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    // // Se vuoi un po' di margine in più
    paddingTop: {xs: 10, sm: 120, md: 90, lg: 50},
    // paddingBottom: { xs: 4, md: 8 },

};

const HomePage = () => {
    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );


    // Media queries for responsive behavior
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

    const cer_link = utente.cer
        ? Routes_path.COMUNITA_ENERGETICA + utente.cer
        : Routes_path.COMUNITA_ENERGETICHE;

    const [showSignIn, setShowSignIn] = useState(false);

    // Funzione per togglare il Collapse
    const toggleSignIn = () => {
        setShowSignIn((prev) => !prev);
    };

    return (
        <Box>
            <Parallax filter image={hero_image}>
                <Box sx={[{...parallaxContainer}]}>
                    {/*<Grid container>*/}
                    {/*    <Grid item xs={12} sm={12} md={12} lg={12}>*/}
                    {utente.errorsStack.status == ErrorStatusTypes.PENDING ? (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <CircularProgress size={100} color="info"/>
                            </Grid>
                        </Grid>
                    ) : (
                        <Fade in timeout={3000}>
                            <Box>
                                {/*<Box sx={{mb: "5vh",}}>*/}
                                <Box sx={{mb: "5vh",}}>
                                    <Slide in direction="up" timeout={1500}>
                                        <Typography variant="h1" color="#fff"
                                                    sx={{mt: isSmallScreen ? "5vh" : isMediumScreen ? "12vh" : "15vh"}}>
                                            Cer43
                                        </Typography>
                                    </Slide>
                                    <Slide in direction="up" timeout={2500}>
                                        <Box>
                                            <Typography
                                                display="inline"
                                                variant="h3"
                                                color={theme.palette.primary.main}
                                                sx={{mt: 1}}
                                            >
                                                C
                                            </Typography>
                                            <Typography
                                                variant="h3"
                                                color="#fff"
                                                sx={{mt: 1}}
                                                display="inline"
                                            >
                                                omunità{" "}
                                            </Typography>
                                            <Typography
                                                display="inline"
                                                variant="h3"
                                                color={theme.palette.primary.main}
                                                sx={{mt: 1}}
                                            >
                                                E
                                            </Typography>
                                            <Typography
                                                variant="h3"
                                                color="#fff"
                                                sx={{mt: 1}}
                                                display="inline"
                                            >
                                                nergetica{" "}
                                            </Typography>

                                            <Typography
                                                display="inline"
                                                variant="h3"
                                                color={theme.palette.primary.main}
                                                sx={{mt: 1}}
                                            >
                                                R
                                            </Typography>
                                            <Typography
                                                variant="h3"
                                                color="#fff"
                                                sx={{mt: 1}}
                                                display="inline"
                                            >
                                                innovabile{" "}
                                            </Typography>
                                        </Box>
                                    </Slide>

                                </Box>
                                {/* Hero Section */}
                                <Box
                                    sx={{
                                        backgroundColor: '#03b0e2dd',
                                        color: 'white',
                                        py: 8,
                                        textAlign: 'center'
                                    }}
                                >
                                    <Container>
                                        <Typography variant="h3" gutterBottom>
                                            Benvenuti in CER43
                                        </Typography>
                                        <Typography variant="h6" gutterBottom>
                                            {`Unisciti a noi per una gestione sostenibile e condivisa dell'energia rinnovabile.`}
                                        </Typography>
                                        {!utente?.id &&
                                            <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
                                                <Button variant="contained" color="secondary" size="large"
                                                        component={ReactLink}
                                                        to={"https://www.gruppoe43.it/cer43"} target="_blank">
                                                    Registrati
                                                </Button>
                                                <Button variant="outlined" color="inherit" size="large"
                                                        onClick={toggleSignIn}>
                                                    {showSignIn ? 'Chiudi' : 'Sei già registrato? Accedi'}
                                                </Button>
                                            </Stack>
                                        }

                                        {!utente?.id &&
                                            <Collapse in={showSignIn}>
                                                <Box mt={4} display="flex" justifyContent="center">
                                                    <Paper elevation={3} sx={{padding: 4, maxWidth: 400}}>
                                                        <SignIn/> {/* Il tuo componente SignIn */}
                                                    </Paper>
                                                </Box>
                                            </Collapse>
                                        }
                                        {utente.id &&
                                            <Button variant="outlined" color="inherit" size="large"
                                                    component={ReactLink}
                                                    to={cer_link}
                                            >
                                                {`Benvenuto ${utente.first_name}. Accedi alla tua area documenti`}
                                            </Button>
                                        }
                                    </Container>
                                </Box>
                                {/* Informazioni generali */}
                                <Container sx={{my: 8, backgroundColor: '#03b0e2aa', py: 8}}>
                                    <Grid container spacing={{xs: 1, md: 2, lg: 4}}>
                                        <Grid item xs={12} md={6}>
                                            <Paper sx={{padding: 4}}>
                                                <Typography variant="h5" gutterBottom>
                                                    {`Cos'è la Comunità Energetica?`}
                                                </Typography>
                                                <Typography>
                                                    {`Le comunità energetiche sono organizzazioni no-profit, 
                                                            territorialmente diffuse, composte da produttori e 
                                                            consumatori di energia elettrica, finalizzate alla 
                                                            produzione e all’autoconsumo efficiente di energia 
                                                            elettrica da fonti rinnovabili.`}
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Paper sx={{padding: 4}}>
                                                <Typography variant="h5" gutterBottom>
                                                    Come funziona?
                                                </Typography>
                                                <Typography sx={{whiteSpace: 'pre-line'}}>
                                                    {`Iscrivendoti alla comunità, potrai ricevere per 
                                                            vent’anni un piccolo incentivo sull’energia autoconsumata. 
                                                         \n Grazie a CER43, inoltre, riceverai indicazioni 
                                                            personalizzate per migliorare il tuo consumo energetico.`}
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Container>


                            </Box>
                        </Fade>
                    )}
                    {/*</Grid>*/}
                    {/*</Grid>*/}
                </Box>
            </Parallax>
        </Box>
    );
};

export default HomePage;
