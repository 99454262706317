import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

export interface OrdinamentoLista {
	asc: boolean;
	campo: string;
}

export interface PreferenzeLista {
	numeroRecord?: number;
	page?: number;
	stato?: string;
	ordinamento?: OrdinamentoLista;
	clienteIterId?: number;
	iterSemplificato?: string;
	operatoreId?: number;
	progettistaId?: number;
}

export interface PreferenzeUtente {
	drawer_aperto: boolean;
	dark_mode: boolean;
}

export interface UserStored {
	username?: string;
	email?: string;
	token_access?: string;
	token_refresh?: string;
	timeRefresh?: string;
}

export interface PreferenzeUtenteState {
	userStored?: UserStored;
	preferenzeUtente?: PreferenzeUtente;
	preferenzeListaIter?: PreferenzeLista;
	preferenzeListaRichieste?: PreferenzeLista;
	preferenzeListaSopralluoghi?: PreferenzeLista;
	preferenzeListaAutorizzazioni?: PreferenzeLista;
	preferenzeListaScadenze?: PreferenzeLista;
	preferenzeListaFatturabili?: PreferenzeLista;
	preferenzeListaPods?: PreferenzeLista;
	preferenzeListaProduttori?: PreferenzeLista;
	preferenzeListaClienti?: PreferenzeLista;
	preferenzeListaAdempimenti?: PreferenzeLista;
	preferenzeListaServiziAdempimenti?: PreferenzeLista;
	preferenzeListaCommesse?: PreferenzeLista;
	preferenzeListaVerificheVincoli?: PreferenzeLista;
	preferenzeListaComunitaEnergetiche?: PreferenzeLista;
	// preferenzeListaCommesse: PreferenzeLista;
}

const initialState: PreferenzeUtenteState = {
	preferenzeUtente: { drawer_aperto: true, dark_mode: false },
	preferenzeListaIter: { numeroRecord: 10, page: 1 },
	preferenzeListaRichieste: { numeroRecord: 10, page: 1 },
	preferenzeListaSopralluoghi: { numeroRecord: 10, page: 1 },
	preferenzeListaAutorizzazioni: { numeroRecord: 10, page: 1 },
	preferenzeListaScadenze: { numeroRecord: 10, page: 1 },
	preferenzeListaFatturabili: { numeroRecord: 10, page: 1 },
	preferenzeListaPods: { numeroRecord: 10, page: 1 },
	preferenzeListaProduttori: { numeroRecord: 10, page: 1 },
	preferenzeListaClienti: { numeroRecord: 10, page: 1 },
	preferenzeListaAdempimenti: { numeroRecord: 10, page: 1 },
	preferenzeListaVerificheVincoli: { numeroRecord: 10, page: 1 },
	preferenzeListaServiziAdempimenti: { numeroRecord: 10, page: 1 },
	preferenzeListaComunitaEnergetiche: { numeroRecord: 10, page: 1 },
	// preferenzeListaCommesse: { numeroRecord: 10, page: 1 },
};

export const userPreferenceSlice = createSlice({
	name: "userPreference",
	initialState,
	reducers: {
		setDrawerAperto: (state, action: PayloadAction<boolean>) => {
			state.preferenzeUtente &&
				(state.preferenzeUtente.drawer_aperto = action.payload);
		},
		// setPreferenzeListaCommesse: (
		// 	state,
		// 	action: PayloadAction<PreferenzeLista>
		// ) => {
		// 	state.preferenzeListaCommesse = action.payload;
		// },
		setPreferenzeListaIter: (state, action: PayloadAction<PreferenzeLista>) => {
			state.preferenzeListaIter = action.payload;
		},
		setPreferenzeListaRichieste: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			state.preferenzeListaRichieste = action.payload;
		},
		setPreferenzeListaSopralluoghi: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			state.preferenzeListaSopralluoghi = action.payload;
		},
		setPreferenzeListaAutorizzazioni: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			state.preferenzeListaAutorizzazioni = action.payload;
		},
		setPreferenzeListaScadenze: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			state.preferenzeListaScadenze = action.payload;
		},
		setPreferenzeListaFatturabili: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			state.preferenzeListaFatturabili = action.payload;
		},
		setPreferenzeListaPods: (state, action: PayloadAction<PreferenzeLista>) => {
			state.preferenzeListaPods = action.payload;
		},
		setPreferenzeListaProduttori: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			console.log("action.payload", action.payload);
			state.preferenzeListaProduttori = action.payload;
		},
		setPreferenzeListaClienti: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			console.log("action.payload", action.payload);
			state.preferenzeListaClienti = action.payload;
		},
		setPreferenzeListaAdempimenti: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			console.log("action.payload", action.payload);
			state.preferenzeListaAdempimenti = action.payload;
		},
		setPreferenzeListaServiziAdempimenti: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			console.log("action.payload", action.payload);
			state.preferenzeListaServiziAdempimenti = action.payload;
		},
		setPreferenzeListaCommesse: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			console.log("action.payload", action.payload);
			state.preferenzeListaCommesse = action.payload;
		},
		setPreferenzeListaVerificheVincoli: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			console.log("action.payload", action.payload);
			state.preferenzeListaVerificheVincoli = action.payload;
		},
		setPreferenzeListaComunitaEnergetiche: (
			state,
			action: PayloadAction<PreferenzeLista>
		) => {
			console.log("action.payload", action.payload);
			state.preferenzeListaComunitaEnergetiche = action.payload;
		},
		setUserStored: (state, action: PayloadAction<UserStored>) => {
			state.userStored = action.payload;
		},
		resetUserStored: (state) => {
			state.userStored = undefined;
		},
	},
	extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
export const {
	setDrawerAperto,
	setPreferenzeListaIter,
	setPreferenzeListaRichieste,
	setPreferenzeListaSopralluoghi,
	setPreferenzeListaAutorizzazioni,
	setPreferenzeListaScadenze,
	setPreferenzeListaFatturabili,
	setPreferenzeListaPods,
	setPreferenzeListaProduttori,
	setPreferenzeListaClienti,
	setPreferenzeListaAdempimenti,
	setPreferenzeListaServiziAdempimenti,
	setPreferenzeListaCommesse,
	setPreferenzeListaVerificheVincoli,
	setPreferenzeListaComunitaEnergetiche,
	setUserStored,
	resetUserStored,
} = userPreferenceSlice.actions;

export const userPreferenceReducer = userPreferenceSlice.reducer;
