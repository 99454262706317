import React from "react";
import {Routes_path} from "../../routerApp";

import {styled} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Header from "./Header";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    sideMenuDrawerWidth: number;
    // miniSideMenuDrawerWidth: number;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) =>
        prop !== "open" && prop !== "sideMenuDrawerWidth",
})<AppBarProps>(({theme, open, sideMenuDrawerWidth}) => ({
    zIndex: theme.zIndex.drawer + 1,
    // width: `calc(100% - ${miniSideMenuDrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: sideMenuDrawerWidth,

        // width: `calc(100% - ${sideMenuDrawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface TopBarProps {
    open?: boolean;
    sideMenuDrawerWidth: number;
    miniSideMenuDrawerWidth: number;
    appBarAltezza: number;
    logoPiccolo: any;
    tooggleDrawer: () => void;
}

export default function TopBar({
                                   open,
                                   sideMenuDrawerWidth,
                                   miniSideMenuDrawerWidth,
                                   appBarAltezza,
                                   logoPiccolo,
                                   tooggleDrawer,
                               }: TopBarProps) {
    return (
        <AppBar
            position="fixed"
            open={open}
            sideMenuDrawerWidth={sideMenuDrawerWidth}
            sx={{
                height: miniSideMenuDrawerWidth,
                pr: 0,
                width: {
                    xs: open ? `calc(100% - ${sideMenuDrawerWidth}px)` : `100%`,
                    // sm: open
                    // 	? `calc(100% - ${sideMenuDrawerWidth}px)`
                    // 	: `calc(100% - ${miniSideMenuDrawerWidth}px)`,
                },
                minHeight: appBarAltezza,
                // py: 3,
            }}
        >
            <Toolbar sx={{pr: 0}}>
                <IconButton
                    // color="white"
                    aria-label="open drawer"
                    onClick={tooggleDrawer}
                    edge="start"
                    sx={{
                        marginRight: "36px",
                        // ...(open && { display: "none" }),
                        color: "white",
                    }}
                    size="large"
                >
                    <img
                        src={logoPiccolo}
                        alt="GI"
                        style={{
                            height: miniSideMenuDrawerWidth * 0.7,
                            // paddingTop: 15,
                            // paddingBottom: 15,
                            // paddingLeft: 25,
                            // paddingRight: 25,
                        }}
                    />
                    {/* <MenuIcon /> */}
                </IconButton>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{width: "100%"}}
                >
                    <Typography variant="h6" noWrap display="inline" color="white">
                        {`Engineering I43 – Ingegneria per l’indipendenza energetica`}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-end",
                        }}
                    >
                        <Header/>
                    </Box>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}
