import React from 'react';
import { Utente } from "../../authentication/authenticationSlice";
import { PermissionTypes } from "../../authentication/permissionsGroups";

import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";

interface HeaderMenuControllatoProps {
	utente: Utente;
	path: string;
	label: string;
	gruppiAutorizzati: PermissionTypes[];
	selected: boolean;
	Icon: React.ElementType;
}
const HeaderMenuControllato = ({
	utente,
	path,
	label,
	gruppiAutorizzati,
	selected,
	Icon,
}: HeaderMenuControllatoProps) => {
	const theme = useTheme();
	return (
		<>
			{utenteAutorizzato(utente, gruppiAutorizzati) && (
				<>
					{/* <ListItem component={Link} to={path} key={path} sx={styleListItem}>
					<ListItemIcon style={{ minWidth: "auto" }}>
						<Tooltip title={label}>
							<Icon className={selected ? classes.iconSelected : ""} />
						</Tooltip>
					</ListItemIcon>
					<ListItemText
						style={{ marginLeft: 12 }}
						primary={label}
						className={selected ? classes.iconSelected : ""}
					/>
				</ListItem> */}

					<Button
						aria-controls="simple-menu"
						component={Link}
						size="small"
						// activeStyle={activeStyle}
						// exact
						color="secondary"
						to={path}
						key={path}
						sx={{
							color: selected
								? theme.palette.secondary.main
								: theme.palette.primary.main,
						}}
						// sx={{ backgroundColor: "#222" }}
					>
						{label}
						<IconButton
							aria-label="show 4 new mails"
							size="large"
							color="inherit"
						>
							<Badge badgeContent={0}>
								<Icon />
							</Badge>
						</IconButton>
					</Button>
				</>
			)}
		</>
	);
};

export default HeaderMenuControllato;
