import React from "react";

import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./components/App";
import store, { persistor } from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";

// const store = configureStore();
const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<ReduxProvider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<DndProvider backend={HTML5Backend}>
					<Router>
						<App />
					</Router>
				</DndProvider>
			</PersistGate>
		</ReduxProvider>
	</React.StrictMode>
);
reportWebVitals(console.log);
