// import { ThemeOptions } from "@mui/material/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import "./fontStyle.css";
import { itIT } from "@mui/material/locale";
import { teal, orange } from "@mui/material/colors";

// export const themeOptions: ThemeOptions = {
export const theme = responsiveFontSizes(
	createTheme(
		{
			palette: {
				// type: "light",
				primary: {
					main: "#fa9898",
				},
				secondary: {
					main: "#FF02a2",
				},
				text: {
					primary: "#191919",
					secondary: "#1d4763",
				},
				error: {
					main: "#FF2222",
				},
				info: {
					main: "#0289b0",
				},
				success: {
					main: "#69f0ae",
				},
				// divider: "rgba(121,85,72,0.2)",
				divider: "rgba(25,25,25,0.5)",
				background: {
					default: "#fa9898",
				},
			},
			typography: {
				fontFamily: "Montserrat",
				h2: {
					fontFamily: "Barlow Condensed",
				},
				h3: {
					fontFamily: "Barlow Condensed",
				},
				h1: {
					fontFamily: "Barlow Condensed",
				},
				h5: {
					fontFamily: "Barlow Condensed",
				},
				h6: {
					fontFamily: "Barlow Condensed",
					fontSize: "1.2rem",
				},
				body1: {
					fontWeight: 100,
				},
				body2: {
					fontWeight: 100,
				},
				fontWeightLight: 200,
				fontWeightRegular: 300,
				subtitle1: {
					fontFamily: "Montserrat",
				},
				subtitle2: {
					fontFamily: "Montserrat",
					fontSize: "1.1rem",
					fontWeight: 300,
				},
				h4: {
					fontFamily: "Barlow Condensed",
				},
				overline: {
					fontFamily: "Barlow Condensed",
				},
			},
			spacing: 8,
			shape: {
				borderRadius: 4,
			},
			transitions: {
				duration: {
					shortest: 150,
					shorter: 200,
					short: 250,
					// most basic recommended timing
					standard: 300,
					// this is to be used in complex animations
					complex: 375,
					// recommended when something is entering screen
					enteringScreen: 225,
					// recommended when something is leaving screen
					leavingScreen: 195,
				},
				easing: {
					// This is the most common easing curve.
					easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
					// Objects enter the screen at full velocity from off-screen and
					// slowly decelerate to a resting point.
					easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
					// Objects leave the screen at full velocity. They do not decelerate when off-screen.
					easeIn: "cubic-bezier(0.4, 0, 1, 1)",
					// The sharp curve is used by objects that may return to the screen at any time.
					sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
				},
			},
			components: {
				MuiButton: {
					styleOverrides: {
						root: {
							// background: "linear-gradient(45deg, #90a4ae 30%, #607d8b 90%)",
							// border: 0,
							// borderRadius: 3,
							// boxShadow: "0 3px 5px 2px rgba(156, 39, 176, .3)",
							// color: "white",
							// height: 30,
							// padding: "0 30px",
							// marginLeft: 1,
							// marginRight: 1,
						},
					},
				},

				MuiAppBar: {
					styleOverrides: {
						root: {
							background: "linear-gradient(45deg, #03b0e2 30%, #f8ec06 90%)",
						},
					},
					defaultProps: {
						position: "sticky",
					},
				},
			},
		},
		itIT
	)
);
