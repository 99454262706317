import {fetchUtentiApplicativo, fetchUtentiStaff} from "./authentication/authenticationSlice";

import {
	UtenteLogged,
} from "./authentication/authenticationSlice";


export const fetchAnagrafiche = (
	dispatch: any,
	utente: UtenteLogged,
	ritardoScaglionamento: number, // Ritarda ogni dispatch di 1000 == secondo
	// comuniUltimoAggiornamento?: string
) => {
	const actions = [
		() => dispatch(fetchUtentiStaff({})),
		() => dispatch(fetchUtentiApplicativo({})),
	];

	if (utente.isLogged) {
		actions.forEach((action, index) => {
			setTimeout(action, index * ritardoScaglionamento); // Ritarda ogni dispatch di 1 secondo
		});
	}
};
