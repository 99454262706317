import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { useForm, SubmitHandler } from "react-hook-form";

import { useNavigate, useLocation } from "react-router-dom";

import {
	UtenteLogged,
	ChangePassword,
	changePassword,
	resetErrorStatus,
} from "./authenticationSlice";
import { ErrorStatusTypes } from "../common/errorsDeclarations";

import {
	Typography,
	Link,
	Grid,
	Box,
	Container,
	FormControlLabel,
	CircularProgress,
	Avatar,
	Button,
	CssBaseline,
	TextField,
	Checkbox,
	Backdrop,
} from "@mui/material";
import logo from "../../../static/images/logo/Logo_GruppoE43.png";


const ChangePasswordPage = ({ ...props }) => {
	const dispatch = useAppDispatch();

	const utenteLogged: UtenteLogged = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const [saving, setSaving] = useState(false);

	type LocationState = {
		from: {
			pathname: string;
		};
	};
	const location = useLocation();
	const { from } = (location.state as LocationState) || {
		from: { pathname: "/" },
	};
	const {
		handleSubmit,
		register,
		formState: { errors },
		watch,
		setValue,
		setError,
		reset,
	} = useForm<ChangePassword>();
	// const listaCampi = Object.keys(sopralluogo) as Array<keyof ChangePassword>;

	useEffect(() => {
		const fieldsErrors = utenteLogged?.errorsStack?.fieldsErrors;

		const setFieldError = (
			fieldName: "old_password" | "new_password" | "new_password2"
		) => {
			fieldsErrors?.[fieldName] &&
				setError(fieldName, {
					type: "server",
					message: fieldsErrors[fieldName].toString(),
				});
		};

		setFieldError("old_password");
		setFieldError("new_password");
		setFieldError("new_password2");
	}, [utenteLogged?.errorsStack?.fieldsErrors]);
	// useEffect(() => {
	// 	utenteLogged?.errorsStack?.fieldsErrors?.old_password &&
	// 		setError("old_password", {
	// 			type: "server",
	// 			message: utenteLogged.errorsStack.fieldsErrors.old_password.toString(),
	// 		});
	// 	utenteLogged?.errorsStack?.fieldsErrors?.new_password &&
	// 		setError("new_password", {
	// 			type: "server",
	// 			message: utenteLogged.errorsStack.fieldsErrors.new_password.toString(),
	// 		});
	// 	utenteLogged?.errorsStack?.fieldsErrors?.new_password2 &&
	// 		setError("new_password2", {
	// 			type: "server",
	// 			message: utenteLogged.errorsStack.fieldsErrors.new_password2.toString(),
	// 		});
	// }, [utenteLogged?.errorsStack?.fieldsErrors]);

	const navigate = useNavigate();

	useEffect(() => {
		if (utenteLogged?.errorsStack?.status === ErrorStatusTypes.SUCCESS) {
			navigate("/");
			dispatch(resetErrorStatus());
		}
	}, [utenteLogged?.errorsStack?.status]);

	const onSubmit: SubmitHandler<ChangePassword> = (passwordStack) => {
		dispatch(changePassword(passwordStack));
	};

	return (
		<Container component="main" maxWidth="xs">
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={saving}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<CssBaseline />
			<Box
				sx={{
					marginTop: (theme) => theme.spacing(8),
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				{/* <StyledDiv> */}
				<Avatar alt="Logo Cer43" src={logo} />
				<Typography component="h1" variant="h5">
					Cer43
				</Typography>
				<Typography component="h2" variant="h5">
					{utenteLogged.username} Cambia Password
				</Typography>
				{/* {errors.detail && <Alert severity="error">{errors.detail}</Alert>} */}
				<Box sx={{ width: "100%", marginTop: (theme) => theme.spacing(1) }}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<TextField
							label="Password attuale"
							margin="normal"
							variant="outlined"
							fullWidth={true}
							type="password"
							required
							{...register("old_password", { required: "Campo obbligatorio" })}
							error={!!errors?.old_password}
							helperText={errors?.old_password?.message}
						/>
						<TextField
							label="Nuova Password"
							margin="normal"
							variant="outlined"
							fullWidth={true}
							type="password"
							required
							{...register("new_password", { required: "Campo obbligatorio" })}
							error={!!errors?.new_password}
							helperText={errors?.new_password?.message}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							label="Ripeti Password"
							fullWidth={true}
							type="password"
							required
							{...register("new_password2", {
								required: "Campo obbligatorio",
								validate: (value) =>
									value === watch("new_password") ||
									"Le password non coincidono",
							})}
							error={!!errors?.new_password2}
							helperText={errors?.new_password2?.message}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							sx={{ margin: (theme) => theme.spacing(3, 0, 2) }}
						>
							Cambia Password
						</Button>
					</form>
				</Box>
			</Box>
		</Container>
	);
};

export default ChangePasswordPage;
