import React from "react";

import { NavLink } from "react-router-dom";

import ProfileMenu from "./ProfileMenu";
import { userIsLogged } from "../UserIsLogged";
import { Routes_path } from "../../routerApp";
import { PermissionTypes } from "../../../components/authentication/permissionsGroups";
import HeaderMenuControllato from "./HeaderMenuControllato";
import { Utente } from "../../authentication/authenticationSlice";

import IconButton from "@mui/material/IconButton";
import LoginIcon from "@mui/icons-material/Login";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const MenuItemStyle = {
	display: { sm: "inline-flex", xs: "flex" },
	padding: 0,
	ml: 1,
};

interface HeaderMenuProps {
	// TODO gestire il tipo utente
	utente: Utente;
	resetUtente: () => void;
	activeStyle: React.CSSProperties;
}

const HeaderMenu = ({ utente, resetUtente, activeStyle }: HeaderMenuProps) => {

	return (
		<>
			<HeaderMenuControllato
				utente={utente}
				path={Routes_path.HOME}
				label="HOME"
				gruppiAutorizzati={[PermissionTypes.ALL]}
				selected={location.pathname == Routes_path.HOME}
				Icon={HomeIcon}
			/>

			{userIsLogged(utente) ? (
				<Box sx={MenuItemStyle}>
					<ProfileMenu
						utente={utente}
						resetUtente={resetUtente}
						activeStyle={activeStyle}
					/>
				</Box>
			) : (
				<Button
					aria-controls="simple-menu"
					component={NavLink}
					size="small"
					// activeStyle={activeStyle}
					// exact
					color="inherit"
					to={Routes_path.SIGNIN}
				>
					accedi
					<IconButton
						aria-label="account of current user"
						aria-controls="primary-search-account-menu"
						aria-haspopup="true"
						color="inherit"
						size="large"
					>
						<LoginIcon />
					</IconButton>
				</Button>
			)}
		</>
	);
};

export default HeaderMenu;
