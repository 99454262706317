import React from "react";
import { Link } from "react-router-dom";

import { styled, experimental_sx as sx } from "@mui/material/styles";
import under_construction from "../../../static/images/common/under_construction.png";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const UnderConstruction = () => {
	return (
		<Stack
			direction="column"
			justifyContent="center"
			alignItems="center"
			spacing={2}
		>
			<Box>
				<Typography variant="h4">In costruzione</Typography>
			</Box>
			<Box>
				<img src={under_construction} alt="under construction" />
			</Box>
			<Box>
				<Button
					sx={{
						backgroundColor: "#f8d300",
						"&:hover": {
							backgroundColor: "#a1887f",
						},
						color: "#222222",
						elevation: 2,
						align: "center",
					}}
					variant="contained"
					component={Link}
					to="/"
				>
					{"Back to Homepage"}
				</Button>
			</Box>
		</Stack>
	);
};

export default UnderConstruction;
