import React from "react";
import { RootState, useAppDispatch } from "../redux/store";
import AppComposer from "./AppComposer";
import {
	utenteTokenRefresh,
	UtenteLogged,
} from "./authentication/authenticationSlice";
import {ErrorStatusTypes,} from "./common/errorsDeclarations";

import { useSelector } from "react-redux";
import { ACCESS_TOKEN } from "../api/apiUtils";

import { fetchAnagrafiche } from "./fetchAnagrafiche";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

const AppTokenMng = () => {
	const dispatch = useAppDispatch();

	const minutiRefreshToken = useSelector<RootState, number>(
		(state) => state.authentication.minutiRefreshToken
	);
	const minutiRefreshAnagrafiche = useSelector<RootState, number>(
		(state) => state.authentication.minutiRefreshAnagrafiche
	);

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const tokenStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.authentication.token.errorsStack.status
	);

	React.useEffect(() => {
		const timeRefresh = minutiRefreshToken * 60 * 1000;
		const access_token = localStorage.getItem(ACCESS_TOKEN);
		access_token !== null && dispatch(utenteTokenRefresh());
		const interval = setInterval(() => {
			const access_token = localStorage.getItem(ACCESS_TOKEN);
			access_token !== null && dispatch(utenteTokenRefresh());
		}, timeRefresh);
		return () => clearInterval(interval);
	}, []);

	React.useEffect(() => {
		fetchAnagrafiche(dispatch, utente, 200);

		const timeRefresh = minutiRefreshAnagrafiche * 60 * 1000;

		const intervalloAggiornamentoAnagrafiche = setInterval(() => {
			fetchAnagrafiche(dispatch, utente, 5000);
		}, timeRefresh);

		// setIntervalId(id);

		return () => clearInterval(intervalloAggiornamentoAnagrafiche);
	}, [dispatch, utente.isLogged]);

	return (
		<>
			{!utente.isLogged && tokenStatus == ErrorStatusTypes.PENDING ? (
				<Box sx={{ width: "100%" }}>
					<Stack
						sx={{ height: 400 }}
						justifyContent="center"
						alignItems="center"
						// direction={{ xs: "column", sm: "row" }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
					>
						<Card variant="outlined" sx={{ width: "50%" }}>
							<CardContent>
								<Typography variant="h3" gutterBottom>
									Verifica autenticazione in corso
								</Typography>
								<LinearProgress />
							</CardContent>
						</Card>
					</Stack>
				</Box>
			) : (
				<AppComposer />
			)}
		</>
	);
};

export default AppTokenMng;
