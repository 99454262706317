import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import storage from "redux-persist/lib/storage";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import { userPreferenceReducer } from "../components/userPreference/userPreferenceSlice";
import { authenticationReducer } from "../components/authentication/authenticationSlice";

import { comunitaEnergeticheSliceReducer } from "components/main/comunitaEnergetiche/comunitaEnergeticheSlice";

const persistConfig = {
	key: `root_${process.env.API_URL}`,
	storage,
	whitelist: [
		"userPreferenceState",
		// "authentication",
		"regimiCommercialiState",
		"tipiTensioneState",
		"tipiPraticaState",
		"tipiServizioState",
		"clientiState",
		"installatoriState",
		"gestoriState",
		"comuniState",
		// "produttoriSliceReducer",
	], // Elenco dei reducer persistenti
};

const rootReducer = combineReducers({
	userPreferenceState: userPreferenceReducer,
	authentication: authenticationReducer,

	comunitaEnergeticheState: comunitaEnergeticheSliceReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== "production",
	// middleware: [thunk],
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
export const persistor = persistStore(store);
