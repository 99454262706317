import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";

import {RootState, useAppDispatch} from "../../../../redux/store";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {UtenteLogged} from "../../../authentication/authenticationSlice";
import {
    ComunitaEnergetica,
    getComunitaEnergetica,
    AllegatoComunitaEnergetica,
} from "../comunitaEnergeticheSlice";
import AllegatiGeneraliCerPage from "./AllegatiGeneraliCerPage";


import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import Stack from "@mui/material/Stack";
import {Grid, Paper} from "@mui/material";

import ListItemText from "@mui/material/ListItemText";

import InboxIcon from "@mui/icons-material/Inbox";
import AllegatiPersonaliCerPage from "./AllegatiPersonaliCerPage";

const ComunitaEnergeticaPage = () => {
    const dispatch = useAppDispatch();
    const {comunitaEnergetica_id} = useParams();

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    const [comunitaEnergeticaId] = useState<number>(
        utente?.cer || parseInt(comunitaEnergetica_id || "")
    );


    const comunitaEnergetica = useSelector<
        RootState,
        ComunitaEnergetica | undefined
    >((state) =>
        state.comunitaEnergeticheState.comunitaEnergetiche.results.find(
            (c) => c.id == comunitaEnergeticaId
        )
    );

    const inizialeMaiuscola = (testo: string) => {
        if (!testo) return ""; // Controlla se la stringa esiste
        return testo.charAt(0).toUpperCase() + testo.slice(1).toLowerCase();
    };


    useEffect(() => {
        if (comunitaEnergeticaId) {
            dispatch(getComunitaEnergetica(comunitaEnergeticaId));
        }
    }, []);


    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                p: 2,
            }}
        >
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={!comunitaEnergetica}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <Paper elevation={2} sx={{p: 2, backgroundColor: "#eee"}}>
                {comunitaEnergetica ? (
                    <Card sx={{mt: 1, pb: 1}}>
                        <CardHeader
                            title={
                                <Box>
                                    <Typography variant="h2" color="white">
                                        {`Comunità Energetica ${comunitaEnergetica.nome}`}
                                    </Typography>
                                    <Typography variant="h3" color="#eee" gutterBottom>
                                        {comunitaEnergetica.descrizione}
                                    </Typography>
                                </Box>
                            }
                            sx={{
                                background:
                                    "linear-gradient(45deg, #03b0e2  30%,  #03b0e2aa 90%)",
                                // background: "linear-gradient(45deg, #03b0e2 30%, #f8ec06 90%)",
                            }}
                        />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={6}>
                                    <Card sx={{
                                        p: 2,
                                        background: "linear-gradient(45deg, #8bddf433  30%,  #03b0e211 90%)"
                                    }}>
                                        <CardHeader
                                            title={<Typography
                                                variant="h4"
                                                color="text.secondary"
                                                gutterBottom
                                            >
                                                Documenti della Comunità Energetica
                                            </Typography>}
                                        />
                                        <CardContent sx={{px: 8, mt: 2}}>
                                            <AllegatiGeneraliCerPage comunitaEnergetica={comunitaEnergetica}/>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={12} lg={6}>
                                    <Card sx={{
                                        p: 2,
                                        background: "linear-gradient(45deg, #8bf4c733  30%,  #03b0e211 90%)"
                                    }}>

                                        <CardContent>
                                            <CardContent>
                                                <Typography
                                                    variant="h4"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    {"Documenti riservati dell'associato "}
                                                    {utente.cer
                                                        ? inizialeMaiuscola(utente.first_name || "") + " " + inizialeMaiuscola(utente.last_name || "") : "utente registrato"}
                                                </Typography>
                                            </CardContent>
                                            <CardContent sx={{px: 8, mt: 0.2}}>
                                                {utente.id &&
                                                    <AllegatiPersonaliCerPage comunitaEnergetica={comunitaEnergetica} />
                                                }
                                            </CardContent>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ) : (
                    <Paper sx={{p: 2, backgroundColor: "lightgoldenrodyellow", mb: 2}}>
                        <Typography variant="h5" color="text.secondary" gutterBottom>
                            <ReportGmailerrorredIcon/>
                            Errore caricamento Comunità Energetica
                        </Typography>
                    </Paper>
                )}
            </Paper>
        </Box>
    );
};

export default ComunitaEnergeticaPage;
