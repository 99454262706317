import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../common/errorsDeclarations";
import * as comunitaEnergeticaApi from "../../../api/comunitaEnergetiche/comunitaEnergeticheApi";
import { toast } from "react-toastify";

// import { useDispatch } from "react-redux";

export interface TipoDocumento {
	id: number;
	nome: string;
	descrizione?: string;
}

export interface AllegatoComunitaEnergetica {
	id: number | null;
	tipo_documento: number | null;
	tipo_documento_des?: TipoDocumento;
	descrizione: string;
	comunita_energetica?: number | null;
	autore: string;
	utente_cer: number;
	file: string;
}

export interface ComunitaEnergetica {
	id?: number;
	nome?: string;
	descrizione?: number | null;
	referente?: number;
	data_creazione_cer?: Date | string;
	allegati_cer?: AllegatoComunitaEnergetica[];
	note?: string;

	errorsStack?: ErrorsStack;
}

export interface ComunitaEnergeticheState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: ComunitaEnergetica[];
	errorsStack: ErrorsStack;
}

export interface AllegatiGeneraliCer {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: AllegatoComunitaEnergetica[];
	errorsStack: ErrorsStack;
}

export interface AllegatiPersonaliCer {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: AllegatoComunitaEnergetica[];
	errorsStack: ErrorsStack;
}

export interface ComunitaEnergeticheStrutturaState {
	comunitaEnergetiche: ComunitaEnergeticheState;
	allegatiGeneraliCer: AllegatiGeneraliCer;
	allagatiPersonaliCer: AllegatiPersonaliCer;
}


const initialState: ComunitaEnergeticheStrutturaState = {
	comunitaEnergetiche: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	allegatiGeneraliCer: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	allagatiPersonaliCer: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchComunitaEnergetiche = createAsyncThunk(
	"comunita_energetiche/fetchComunitaEnergetiche",
	async (parametri: {
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await comunitaEnergeticaApi.fetchComunitaEnergetiche(
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const getComunitaEnergetica = createAsyncThunk(
	"comunita_energetiche/getComunitaEnergetica",
	async (comunitaEnergeticaId: number) => {
		return await comunitaEnergeticaApi.getComunitaEnergetica(
			comunitaEnergeticaId
		);
	}
);

export const fetchAllegatiGeneraliCer = createAsyncThunk(
	"comunita_energetiche/fetchAllegatiGeneraliCer",
	async (filtriAllegati: {
		comunitaEnergetica: ComunitaEnergetica
		numeroRecord?: number;
		page?: number;
		search?: string;

	}) => {
		return await comunitaEnergeticaApi.fetchAllegatiGeneraliCer(
			filtriAllegati.comunitaEnergetica,
			filtriAllegati.numeroRecord,
			filtriAllegati.page,
			filtriAllegati.search
		);
	}
);

export const fetchAllegatiPersonaliCer = createAsyncThunk(
	"comunita_energetiche/fetchAllegatiPersonaliCer",
	async (filtriAllegati: {
		comunitaEnergetica: ComunitaEnergetica
		numeroRecord?: number;
		page?: number;
		search?: string;

	}) => {
		return await comunitaEnergeticaApi.fetchAllegatiPersonaliCer(
			filtriAllegati.comunitaEnergetica,
			filtriAllegati.numeroRecord,
			filtriAllegati.page,
			filtriAllegati.search
		);
	}
);

export const comunitaEnergeticheSlice = createSlice({
	name: "comunitaEnergeticheState",
	initialState,
	reducers: {
		// 	resetVerificaVincoliId: (state) => {
		// 		state.nuovaVerificaVincoliId = undefined;
		// 	},
	},
	extraReducers: (builder) => {
		// fetch Comunità Energetiche
		builder.addCase(fetchComunitaEnergetiche.pending, (state) => {
			state.comunitaEnergetiche.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchComunitaEnergetiche.fulfilled, (state, action) => {
			state.comunitaEnergetiche = action.payload;
			state.comunitaEnergetiche.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchComunitaEnergetiche.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.comunitaEnergetiche.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Comunità Energetica
		builder.addCase(getComunitaEnergetica.pending, (state) => {
			state.comunitaEnergetiche.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getComunitaEnergetica.fulfilled, (state, action) => {
			state.comunitaEnergetiche.results =
				state.comunitaEnergetiche.results.filter(
					(comunita) => comunita.id != action.payload.id
				);
			state.comunitaEnergetiche.results.push(action.payload);

			state.comunitaEnergetiche.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getComunitaEnergetica.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.comunitaEnergetiche.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch Allegati Generali Cer
		builder.addCase(fetchAllegatiGeneraliCer.pending, (state, action) => {
			state.comunitaEnergetiche.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchAllegatiGeneraliCer.fulfilled, (state, action) => {
			state.allegatiGeneraliCer = action.payload;
			state.comunitaEnergetiche.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchAllegatiGeneraliCer.rejected, (state, action) => {
			state.comunitaEnergetiche.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// fetch Allegati Personali Cer
		builder.addCase(fetchAllegatiPersonaliCer.pending, (state, action) => {
			state.comunitaEnergetiche.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchAllegatiPersonaliCer.fulfilled, (state, action) => {
			state.allagatiPersonaliCer = action.payload;
			state.comunitaEnergetiche.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchAllegatiPersonaliCer.rejected, (state, action) => {
			state.comunitaEnergetiche.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

	},
});

// Action creators are generated for each case reducer function
export const {} = comunitaEnergeticheSlice.actions;

export const comunitaEnergeticheSliceReducer = comunitaEnergeticheSlice.reducer;
