import React from "react";

import RouterApp from "./routerApp";
import TopBar from "./common/topBar/TopBar";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import {
	PreferenzeUtente,
	setDrawerAperto,
} from "./userPreference/userPreferenceSlice";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Copyright from "./common/Copyright";

// import logoPiccolo from "../../static/images/logo/logo-I43_biglia.png";
import logoPiccolo from "../../static/images/logo/Logo_GruppoE43.png";


const miniSideMenuDrawerWidth = 70;
const appBarAltezza = 70;

const HeaderSpacer = styled("div")(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: 0,
	marginTop: miniSideMenuDrawerWidth,
	// // necessary for content to be below app bar
	// ...theme.mixins.toolbar,
}));

const AppComposer = () => {
	const dispatch = useAppDispatch();

	const preferenzeUtente = useSelector<RootState, PreferenzeUtente | undefined>(
		(state) => state.userPreferenceState.preferenzeUtente
	);

	const [drawerPreview, setDrawerPreview] = React.useState(false);

	const [drawerIsOpen, setDrawerIsOpen] = React.useState(
		preferenzeUtente ? preferenzeUtente.drawer_aperto : true
		// true
	);

	const tooggleDrawer = () => {
		setDrawerIsOpen(!drawerIsOpen);
		dispatch(setDrawerAperto(!drawerIsOpen));
	};


	return (
		<Box
			sx={{
				display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
			}}
		>
			<CssBaseline />

			<TopBar
				open={drawerIsOpen || drawerPreview}
				// sideMenuDrawerWidth={sideMenuDrawerWidth}
				sideMenuDrawerWidth={0}
				miniSideMenuDrawerWidth={miniSideMenuDrawerWidth}
				appBarAltezza={appBarAltezza}
				logoPiccolo={logoPiccolo}
				tooggleDrawer={tooggleDrawer}
			/>
			<Box
				component="main"
				sx={{ flexGrow: 1, p: 0 }}
			>
				{/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}> */}
				<HeaderSpacer />
				{/* <Box sx={{ minHeight: "90vh",p: 0, m: 0 }}> */}
				{/* <Box component="div" sx={{ p: 0, m: 0 }}> */}
				<Box sx={{ minHeight: "50vh", p: 0, m: 0 }}>
					<RouterApp />
					<Copyright />
				</Box>
			</Box>
		</Box>
	);
};

export default AppComposer;
