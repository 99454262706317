import { handleResponse, handleError, headerset } from "../apiUtils";
import { ComunitaEnergetica } from "components/main/comunitaEnergetiche/comunitaEnergeticheSlice";

const baseUrl = (comunitaEnergetica_id?: number | null, azione?: string) => {
	if (comunitaEnergetica_id) {
		let url = `${process.env.API_URL}/api/comunita_energetiche/${comunitaEnergetica_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/comunita_energetiche/";
	}
};

export function fetchComunitaEnergetiche(
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();
	if (numeroRecord != undefined || search != undefined || page != undefined) {
		url += "?";
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getComunitaEnergetica(comunitaEnergeticaId: number) {
	const url = baseUrl(comunitaEnergeticaId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}


export function fetchAllegatiGeneraliCer(
	comunitaEnergetica: ComunitaEnergetica,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = `${process.env.API_URL}/api/comunita_energetiche/${comunitaEnergetica.id}/allegati_generali_cer/?`;

	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET" , // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchAllegatiPersonaliCer(
	comunitaEnergetica: ComunitaEnergetica,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = `${process.env.API_URL}/api/comunita_energetiche/${comunitaEnergetica.id}/allegati_personali_cer/?`;

	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET" , // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}